import classNames from 'classnames';
import { useOTSTranslation } from 'i18n/useOTSTranslation';

import styles from './styles.module.scss';

const NoResutls = () => {
  const { t } = useOTSTranslation('common');

  return (
    <div className={classNames(styles.item, styles.noResult)}>
      {t('searchResults.noResultsFound')}
    </div>
  );
};

export default NoResutls;
