/* eslint-disable global-require */
import {
  ISearchResult, ISearchResultOther, ISearchResultRegion, ISearchResultResort, SearchCategory,
} from 'api/home/types';
import { IRegionShort } from 'api/region/types';
import { IResortPathInfo } from 'api/resort/types';
import { SearchAltNames } from 'api/type';
import axios from 'axios';
import Fuse from 'fuse.js';
import { getLocale } from 'i18n/locale';
import { IS_SSR } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';
import { I18nKey } from 'i18n/types';
import { Routes } from 'routes';

const overrideThreshold = (!IS_SSR && window.location.search && window.location.search.startsWith('?t='))
  ? (+window.location.search.substr(3))
  : undefined;

Diagnostics.debug(`[search] using threshold override: ${overrideThreshold ?? 'NO. Using default 0.2'}`);

const resortOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: overrideThreshold ?? 0.2,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    'title',
    'title_short',
    'title_original',
    'slug',
    'alt',
  ],
};

const regionOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: overrideThreshold ?? 0.2,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    'title',
    'country',
    'state',
    'slug',
    'alt',
  ],
};

const otherOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: overrideThreshold ?? 0.2,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    'title',
  ],
};

type IOtherHref = {
  uuid: string,
  title: string,
  href: string,
};

const loadFile = async <T>(file: string) => (await axios.get(file)).data as T;

const loadResorts = () => loadFile<Array<IResortPathInfo & { alt?: string[] }>>(`/index/resorts-${getLocale()}.json`);
const loadResortAlts = () => loadFile<Array<SearchAltNames>>(`/index/resorts-alt-${getLocale()}.json`);
const loadResortMisses = () => loadFile<Array<SearchAltNames>>(`/index/resorts-misspellings-${getLocale()}.json`);
const loadRegions = () => loadFile<{ regions: Array<IRegionShort & { alt?: string[] }> }>(`/index/regions-${getLocale()}.json`);

let searchResortsFuse: Fuse<IResortPathInfo> | undefined;
let searchRegionsFuse: Fuse<IRegionShort | undefined>;
let searchOtherFuse: Fuse<IOtherHref | undefined>;

const loadOtherSearches = async (t: (key: I18nKey['common'])=>string) => [{
  uuid: 'homepage.nearbyResorts',
  title: t('homePage.nearbyResorts'),
  href: Routes.nearbyResorts(),
}];

export const fuseSearch = async (query: string, limit: number = 10, t: (key: I18nKey['common'])=>string) => {
  if (!searchResortsFuse) {
    const resorts = await loadResorts();
    const resortsAlts = await loadResortAlts();
    const resortsMisses = await loadResortMisses();

    [...resortsAlts, ...resortsMisses].forEach((i) => {
      // eslint-disable-next-line eqeqeq
      const rs = resorts.find((r) => r.uuid == i.uuid); // == instead of === is intentional here
      if (rs && Array.isArray(i.data)) {
        rs.alt = [...(rs.alt || []), ...i.data];
      }
    });

    searchResortsFuse = new Fuse(resorts, resortOptions);
  }
  if (!searchRegionsFuse) {
    const { regions } = await loadRegions();
    searchRegionsFuse = new Fuse(regions, regionOptions);
  }
  if (!searchOtherFuse) {
    const otherSearches = await loadOtherSearches(t);
    searchOtherFuse = new Fuse(otherSearches, otherOptions);
  }

  const resorts: Array<ISearchResultResort> = searchResortsFuse.search(query).map((r) => ({
    uuid: r.item.uuid,
    title: r.item.title,
    categoryUuid: SearchCategory.resorts,
    resort: r.item,
  }));

  const regions: Array<ISearchResultRegion> = searchRegionsFuse.search(query).map((r) => ({
    uuid: r.item!.uuid,
    title: r.item!.title,
    categoryUuid: SearchCategory.regions,
    region: r.item!,
  }));

  const others: Array<ISearchResultOther> = searchOtherFuse.search(query).map((r) => ({
    uuid: r.item!.uuid,
    title: r.item!.title,
    href: r.item!.href,
    categoryUuid: SearchCategory.other,
  }));

  const result: ISearchResult = {
    categories: [
      {
        title: '',
        uuid: SearchCategory.other,
      },
      {
        title: '',
        uuid: SearchCategory.regions,
      },
      {
        title: '',
        uuid: SearchCategory.resorts,
      },
    ],
    results: {
      other: others.slice(0, limit),
      resorts: resorts.slice(0, limit),
      regions: regions.slice(0, limit),
    },
    pagination: {
      count: resorts.length + regions.length,
      limit: resorts.length + regions.length,
      page: 0,
    },
  };

  return result;
};
