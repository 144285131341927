import property from 'lodash.property';

export const sortByKeyExisting = (arr: any[], key: string): any[][] => {
  const getData = property(key);

  return arr.reduce((acc, curr) => {
    const data = getData(curr);

    if (data) {
      acc[1].push(curr);
    } else {
      acc[0].push(curr);
    }

    return acc;
  }, [[], []]);
};
