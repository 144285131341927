import classNames from 'classnames';
import styles from 'components/blocks/Footer/styles.module.scss';
import { useUnitContext } from 'components/UnitSwitch/context';
import { DistanceType, TempType } from 'i18n/types';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { useCallback } from 'react';

export const UnitToggle = () => {
  const { t } = useOTSTranslation('common');
  const { distance, setTemperatureType, setDistanceType } = useUnitContext();
  const isMetric = distance === DistanceType.METRIC;

  const setMetric = useCallback(() => {
    if (setTemperatureType) {
      setTemperatureType(TempType.CELSIUS);
    }
    if (setDistanceType) {
      setDistanceType(DistanceType.METRIC);
    }
  }, []);

  const setImperial = useCallback(() => {
    if (setTemperatureType) {
      setTemperatureType(TempType.FAHRENHEIT);
    }
    if (setDistanceType) {
      setDistanceType(DistanceType.IMPERIAL);
    }
  }, []);

  return (
    <div className={styles.unitToggle}>
      <div>
        {t('common.units')}
        :
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
      <a onClick={setMetric} className={classNames(isMetric ? styles.active : '')}>
        {t('common.unitsMetric')}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
      <a onClick={setImperial} className={classNames(isMetric ? '' : styles.active)}>
        {t('common.unitsImperial')}
      </a>
      <span>/</span>
    </div>
  );
};
