import queryString from 'query-string';
import { useLayoutEffect, useState } from 'react';

/**
 * Returns 2 booleans
 * noHeader - for omitting header render AFTER server side render hydrated, so client side render matches server
 * noHeaderImmediate - for immediate usage
 *
 * Use immediate one for direct scripts execution, and regular one for rendered layout
 */
export const useNoHeader = () => {
  const parsed = queryString.parse(global?.location?.search ?? '');
  const {
    noHeader,
  } = parsed;
  const [value, setValue] = useState<boolean>(false);
  useLayoutEffect(() => {
    const isNoHeader = (!!noHeader || noHeader === null) || navigator.userAgent.indexOf('OnTheSnow/7') >= 0;
    setValue(isNoHeader);
  }, []);

  return {
    noHeader: value, noHeaderImmediate: (!!noHeader || noHeader === null),
  };
};
