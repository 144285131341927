import classNames from 'classnames';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { ReactComponent as SvgSearch } from 'public/assets/icons/search.svg';

import styles from './styles.module.scss';

interface ISearchBarProps {
  onSubmit: () => void
  onChange: (e: React.SyntheticEvent) => void
  onClose: () => void
  value: string
}

const SearchBar: React.FC<ISearchBarProps> = ({
  onSubmit, value, onChange, onClose,
}) => {
  const { t } = useOTSTranslation('common');

  return (
    <>
      <header>
        <div className={classNames(styles.inner, 'container')}>
          <SvgSearch />
          <form className={styles.search} onSubmit={onSubmit}>
            <input
              type="text"
              placeholder={t('searchResults.helptext')}
              value={value}
              onChange={onChange}
            />
          </form>
        </div>
      </header>
      <button
        type="button"
        aria-label="close search"
        className={styles.close}
        onClick={onClose}
      />
    </>
  );
};

export default SearchBar;
