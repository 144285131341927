import { getCountry } from 'i18n/locale';
import { ECountry, ISlopesColors, SlopeType } from 'i18n/types';
import colors from './slopesColors.module.scss';

/**
 * @see https://mountainnews.jira.com/wiki/spaces/rebuild/pages/1938456577/2.2.1+Resort+Snow+Report
 */
export const preferAcresOrKm = () => ((getCountry() === 'US') ? 'acres' : 'km');

/**
 * @see https://mountainnews.jira.com/wiki/spaces/rebuild/pages/1938456577/2.2.1+Resort+Snow+Report
 */
export const slopeType = (countryCode?: string) => {
  const slopesColors: { [key: string]: ISlopesColors } = {
    [SlopeType.US]: {
      beginner: colors.fillGreen,
      intermediate: colors.fillPrimary,
      advanced: colors.fillBlack,
      expert: colors.fillBlack,
    },
    [SlopeType.FR_PL]: {
      beginner: colors.fillGreen,
      intermediate: colors.fillPrimary,
      advanced: colors.fillRed,
      expert: colors.fillBlack,
    },
    [SlopeType.DE_IT_CZ_SK]: {
      beginner: colors.fillGreen,
      intermediate: colors.fillPrimary,
      advanced: colors.fillRed,
      expert: colors.fillBlack,
    },
  };

  if (countryCode) {
    switch (countryCode) {
      case 'USA':
      case 'CAN':
        return {
          slopType: SlopeType.US,
          slopesColors: slopesColors[SlopeType.US],
        };

      case 'BG':
      case 'DE':
      case 'FI':
      case 'SE':
      case 'SCT':
      case 'GR':
      case 'RU':
      case 'NO':
      case 'SRB':
      case 'IS':
      case 'BA':
      case 'LI':
      case 'SI':
      case 'BE':
      case 'AD-AD':
      case 'MK':
      case 'PT':
      case 'IT':
      case 'CY':
      case 'PL':
      case 'AT-AT':
      case 'CH-CH':
      case 'ES':
      case 'FR-FR':
      case 'HOL':
      case 'SK':
      case 'CZ':
        return {
          slopType: SlopeType.DE_IT_CZ_SK,
          slopesColors: slopesColors[SlopeType.DE_IT_CZ_SK],
        };

      default:
        return {
          slopType: SlopeType.US,
          slopesColors: slopesColors[SlopeType.US],
        };
    }
  } else {
    return {
      slopType: SlopeType.US,
      slopesColors: slopesColors[SlopeType.US],
    };
  }
};

/**
 * @see https://mountainnews.jira.com/wiki/spaces/rebuild/pages/1938456577/2.2.1+Resort+Snow+Report
 */
export const terrainDetailType = () => {
  if (['FR'].includes(getCountry())) {
    return 'number-lifts-kilometers-trails-number-individual-trail';
  }
  if (['AT', 'DE', 'CH', 'IT', 'PL', 'CZ', 'SK'].includes(getCountry())) {
    return 'number-lifts-kilometers-trails-kilometers-individual-trail';
  }
  if (['SW'].includes(getCountry())) {
    return 'number-lifts-number-individual-trail';
  }
  return 'default';
};

export const getImpressum = () => {
  const impressumLinks: Record<ECountry, string | undefined> = {
    [ECountry.FR]: '/news/mentions-legales',
    [ECountry.DE]: '/news/impressum',
    [ECountry.GB]: '/news/legal-notice',
    [ECountry.SK]: '/news/imprint',
    [ECountry.CZ]: '/news/imprint',
    [ECountry.NO]: '/news/legal-notice',
    [ECountry.SE]: '/news/juridisk-information',
    [ECountry.SK]: '/news/imprint',
    [ECountry.IT]: '/news/note-legali',
    [ECountry.PL]: '/news/nota-prawna',
    [ECountry.ES]: '/news/aviso-legal',
    [ECountry.DK]: '/news/firmainformation',
    [ECountry.NL]: '/news/juridische-kennisgeving',
    [ECountry.US]: undefined,
  };

  return impressumLinks[getCountry() as ECountry];
};
