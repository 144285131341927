import { IRegionShort, IWebcamItem } from 'api/region/types';
import { IPaginationResponse, IResortPathInfo } from 'api/resort/types';
import { IPaginableResponse } from 'types';

export enum ResortTopRated {
  overall = 'overall',
  allMtnTerrain = 'allMtnTerrain',
  familyFriendly = 'familyFriendly',
  terrainPark = 'terrainPark',
  beginner = 'beginner',
  apresSki = 'apresSki',
  smallSkiArea = 'smallSkiArea',
  intermediate = 'intermediate',
  expert = 'expert',
}

export enum SearchCategory {
  resorts = 'resorts',
  regions = 'regions',
  other = 'other',
  news = 'news',
}

export type ISearchResult = {
  categories: Array<IResultCategory>;
  results: {
    other: Array<ISearchResultOther>,
    resorts: Array<ISearchResultResort>,
    regions: Array<ISearchResultRegion>,
  }
  pagination: IPaginationResponse;
};

export type ISearchNewsResult = {
  results: Array<ISearchResultNews>;
};

export type IResultCategory = {
  uuid: SearchCategory;
  title: string;
};

export interface ISearchResultCommon {
  uuid: string;
  title: string;
}

export interface ISearchResultResort extends ISearchResultCommon {
  categoryUuid: SearchCategory.resorts;
  resort: IResortPathInfo;
}

export interface ISearchResultOther extends ISearchResultCommon {
  categoryUuid: SearchCategory.other;
  href: string;
}

export interface ISearchResultRegion extends ISearchResultCommon {
  categoryUuid: SearchCategory.regions;
  region: IRegionShort;
}

export interface ISearchResultNews extends ISearchResultCommon {
  categoryUuid: SearchCategory.news;
  news: INews;
}

export type ISearchResultItem = ISearchResultResort | ISearchResultRegion | ISearchResultNews | ISearchResultOther;

export type IPopularSearch = {
  results: Array<string | IRegionShort>;
};

export type IBackgroundImages = {
  image: string;
};

export type ISnowWebcam = {
  webcam: IWebcamItem;
  resort: IResortPathInfo;
};

export type IResortTopRated = {
  resort: IResort;
  resortPath: IResortPathInfo;
};

export type IResort = {
  uuid: string;
  title: string;
  image: string;
  smallImage: string;
  rating: number;
  reviewsCount: number;
};

export type INews = {
  uuid: string;
  title: string;
  description: string;
  image?: string;
  thumbnail?: string;
  categories?: Array<ICategoryNews>;
  slug?: string;
  link?: string;
};

export interface ICategoryNews extends INews {
  slug: string;
}

export type ILastNews = {
  uuid: string;
  title: string;
  description: string;
  image: string;
};

export type INewsFromCategory = {
  pagination: IPaginationResponse;
  newsList: Array<INews>;
};

export type IGuideHomeTopRated = IPaginableResponse<IResortTopRated>;
