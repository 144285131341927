export default {
  white: '#FFFFFF',
  black: '#232323',
  green: '#29903A',
  red: '#FF0000',

  /**
   Primary Blue
   */
  primary: '#006FAC',
  primaryAlt: '#11ABD8',

  /**
 Secondary Deep Blue
 */
  secondary: '#304769',
  /*
  Secondary Dark Blue
 */
  secondaryAlt: '#005889',

  statusPartOpen: '#FF9E22',
  statusOpen: '#3BA82D',
  statusClosed: '#F17850',
};

export const BLUR_CONTENT_STYLE_NAME = 'blur-content';
export const ANIMATED_BLUR_CONTENT_STYLE_NAME = 'animated-blur';
