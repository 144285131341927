import { NextLink } from 'components/blocks/NextLink';
import { useRouter } from 'next/router';
import { AnchorHTMLAttributes, useCallback, useEffect } from 'react';
import { useAnalytics } from 'util/analytics';

const SearchLink = (
  {
    query, href, shouldFollowFocusedLink, children, ...props
  }: AnchorHTMLAttributes<HTMLAnchorElement> & { href: string, query: string, shouldFollowFocusedLink?:boolean },
) => {
  const a = useAnalytics();
  const router = useRouter();

  const onClick = useCallback(() => {
    a.searchClick({ url: href, query });
  }, [a, href, query]);

  useEffect(() => {
    if (shouldFollowFocusedLink) {
      onClick();
      router.push(href);
    }
  }, [shouldFollowFocusedLink]);

  return <NextLink onClick={onClick} href={href} {...props}>{children}</NextLink>;
};

export default SearchLink;
