import { useRouter } from 'next/router';
import { IS_SSR } from 'ots-constants';
import { REVIEW_ANCHOR } from 'page-components/resort/constants';
import { useEffect, useMemo } from 'react';

export const useDefaultScrollToTop = (offsetLeft?: number | null, offsetTop?: number | null) => {
  const router = useRouter();
  const path = router?.asPath;
  const [, hash] = useMemo(() => path.split('#'), [path]);
  const scrollValues = { top: offsetTop ?? 0, left: offsetLeft ?? 0 };

  useEffect(() => {
    if (!IS_SSR && hash !== REVIEW_ANCHOR) {
      window.scroll({
        top: scrollValues.top,
        left: scrollValues.left,
      });
    }
  }, [hash, path]);
};
