import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsFetching } from 'react-query';

import {
  IAdUnitContext,
  IAdUnitContextExt,
  ICandidate,
  Timeout,
} from 'googlead/components/AdUnitContext/types';
import { IS_SSR } from 'ots-constants';

import { isPrebidInitialized, onPrebidReadyMaker } from 'googlead/components/AdUnitContext/utils';
import { Diagnostics } from 'util/diagnostics';

export const ReactAdUnitContext = React.createContext<(IAdUnitContext & IAdUnitContextExt) | null>(null);

export const activeAds: { [id: string]: ICandidate } = {};
let idCounter: number = 0;
const AD_DISTANCE = 400;

export const AdUnitContext = (props: IAdUnitContext & { children: React.ReactNode }) => {
  const { children, ...value } = props;
  const [isReady, setIsReady] = useState<boolean>(isPrebidInitialized());

  const timeout = useRef<Timeout | null>(null);
  const timeout2 = useRef<Timeout | null>(null);

  const isFetching = useIsFetching();

  const setAdReadyForInstall = useCallback(
    () => {
      setIsReady(true);
      Diagnostics.message({ message: 'AdUnitContext setIsReady', tag: 'ad prebid' });
    },
    [setIsReady],
  );

  const updateCandidates = useCallback(() => {
    Object.keys(activeAds).forEach((id) => {
      if (activeAds[id].ref.current) {
        const { top } = activeAds[id].ref.current!.getBoundingClientRect();
        activeAds[id].top = top;
      }
    });
    let prev: ICandidate | null = null;
    Object.keys(activeAds)
      .filter((id) => activeAds[id].top === 0).forEach((id) => {
        activeAds[id].setActive(false);
        activeAds[id].active = false;
      });
    Object.keys(activeAds)
      .filter((id) => activeAds[id].top > 0)
      .sort((a, b) => activeAds[a].top - activeAds[b].top).forEach((id) => {
        if (!prev || (activeAds[id].top - prev.top > AD_DISTANCE)) {
          activeAds[id].setActive(true);
          activeAds[id].active = true;
          activeAds[id].ref.current?.setAttribute('title',
            prev ? `Debug: Requested ad unit, cause distance to prev one is ${activeAds[id].top - prev.top}. My id is ${id}.`
              : `Debug: Requested ad unit, cause first on page. My id is ${id}.`);
          prev = activeAds[id];
        } else {
          activeAds[id].setActive(false);
          activeAds[id].active = false;
          activeAds[id].ref.current?.setAttribute('title',
            `Debug: Skipped ad unit, cause distance to prev one is ${activeAds[id].top - prev.top}. My id is ${id}.`);
        }
      });
  }, []);

  const scheduleUpdate = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (timeout2.current) {
      clearTimeout(timeout2.current);
    }
    timeout.current = setTimeout(() => {
      updateCandidates();
    }, 10);
    timeout2.current = setTimeout(() => {
      updateCandidates();
    }, 1000);
  }, []);

  const registerCandidate = useCallback((ref: React.MutableRefObject<HTMLDivElement>, setActive: (active: boolean) => void) => {
    if (IS_SSR) {
      return '';
    }
    if (ref.current && ref.current.id) {
      return ref.current.id;
    }
    // eslint-disable-next-line no-plusplus
    const newId = `candidate-${idCounter++}`;
    activeAds[newId] = {
      active: false,
      ref,
      setActive,
      top: 0,
    };
    scheduleUpdate();
    return newId;
  }, []);

  const unRegisterCandidate = useCallback((id: string) => {
    if (IS_SSR) {
      return;
    }
    delete activeAds[id];
    scheduleUpdate();
  }, []);

  const contextValue = useMemo(() => ({
    ...value,
    registerCandidate,
    unRegisterCandidate,
    isReady,
  }), [value, isReady]);

  useEffect(() => {
    window.addEventListener('resize', scheduleUpdate);
    return () => window.removeEventListener('resize', scheduleUpdate);
  }, [scheduleUpdate]);

  useEffect(() => {
    scheduleUpdate();
  }, [isFetching]);

  useEffect(() => {
    if (!isReady) {
      onPrebidReadyMaker(setAdReadyForInstall);
    }
  }, []);

  return (
    // @ts-ignore
    <ReactAdUnitContext.Provider value={contextValue}>
      {children}
    </ReactAdUnitContext.Provider>
  );
};

export const useAdUnitContext = () => {
  const values = useContext(ReactAdUnitContext);
  if (!values) {
    throw new Error('useAdUnitContext should be used within AdUnitContext');
  }
  return values;
};
