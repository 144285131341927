import { getLocale } from 'i18n/locale';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { isProductionServer } from 'ots-constants';
import React from 'react';

export const APP_SCHEME = 'onthesnow://';

export const HeadMeta = ({
  title,
  description,
  image,
  keywords,
  imageAlt,
  ogType = 'website',
  redirectTo,
  keepQuery,
}: {
  title: string,
  description?: string,
  image?: string,
  keywords?: string,
  imageAlt?: string,
  ogType?: string,
  redirectTo?: string | null,
  keepQuery?: boolean,
}) => {
  const { t } = useOTSTranslation('common');
  const route = useRouter();
  const rawCanonical = redirectTo ? (`https://${redirectTo}${route.asPath}`) : (process.env.NEXT_PUBLIC_WEBSITE_DOMAIN + route.asPath);
  const canonical = (rawCanonical.includes('?') && !keepQuery) ? rawCanonical.split('?')[0] : rawCanonical;

  let redirect = redirectTo ? (`https://${redirectTo}${route.asPath}`) : undefined;

  // Temp fix for US/UK url difference
  if (redirect && redirect.endsWith('/trailmap')) {
    redirect = redirect.split('/trailmap').join('/pistemap');
  } else
  if (redirect && redirect.endsWith('/pistemap')) {
    redirect = redirect.split('/pistemap').join('/trailmap');
  }
  // eslint-disable-next-line no-nested-ternary
  const imageFull = image ? (image.startsWith('https://') ? image : (process.env.NEXT_PUBLIC_WEBSITE_DOMAIN + image)) : undefined;

  return (
    <Head>
      <title>{title}</title>
      {redirect && <meta httpEquiv="refresh" content={`0; URL=${redirect}`} />}
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {isProductionServer && canonical && <link rel="canonical" href={canonical} />}
      {/* <!-- Start SmartBanner configuration --> */}
      <meta name="smartbanner:title" content="OnTheSnow Ski & Snow Report" />
      <meta name="smartbanner:author" content="Ski & Snow Conditions" />
      {/* https://github.com/ain/smartbanner.js?tab=readme-ov-file#path-designated-close */}
      <meta name="smartbanner:hide-path" content="/" />
      <meta name="smartbanner:icon-apple" content="https://images.onthesnow.com/images/apple-touch-icon.png" />
      <meta name="smartbanner:icon-google" content="https://images.onthesnow.com/images/apple-touch-icon.png" />
      <meta name="smartbanner:button" content={t('smartBanner.ctaOpen')} />
      <meta name="smartbanner:button-url-apple" content="https://apps.apple.com/us/app/onthesnow-ski-snow-report/id300412347" />
      <meta name="smartbanner:button-url-google" content="https://play.google.com/store/apps/details?id=com.skireport" />
      <meta name="smartbanner:enabled-platforms" content="android,ios" />
      <meta name="smartbanner:close-label" content="Close" />
      {/* <!-- End SmartBanner configuration --> */}
      {/* OPENGRAPH */}
      {canonical && <meta property="og:url" content={canonical} />}
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={getLocale()} />
      {description && <meta property="og:description" content={description} />}
      {imageFull && <meta property="og:image" content={imageFull} />}
      {/* TWITTER */}
      {imageFull && <meta name="twitter:card" content="summary_large_image" />}
      {!imageFull && <meta name="twitter:card" content="summary" />}
      {canonical && <meta property="twitter:url" content={canonical} />}
      <meta name="twitter:site" content="@onthesnow" />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {imageFull && <meta name="twitter:image" content={imageFull} />}
      {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
      <link rel="manifest" href="/manifest.json" />
    </Head>
  );
};
