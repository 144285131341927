import classNames from 'classnames';
import { getNewsPrefix } from 'news-translations';
import Link from 'next/link';
import { AnchorHTMLAttributes } from 'react';
import { useSettingsPrefetch } from 'settings/SettingsContext';
import styles from './styles.module.scss';

export const NextLink = (
  {
    scroll,
    href,
    children,
    ...props
  }: AnchorHTMLAttributes<HTMLAnchorElement> & { href: string, scroll?: boolean }
  ,
) => {
  const prefetch = useSettingsPrefetch();
  return href.startsWith(`/${getNewsPrefix()}`)
    ? <a href={href} {...props}>{children}</a>
    : <Link scroll={!!scroll} href={href} passHref prefetch={prefetch} {...props}>{children}</Link>;
};

export const NextLinkTr = (
  {
    scroll,
    className,
    children,
    ...props
  }: AnchorHTMLAttributes<HTMLAnchorElement> & { href: string, scroll?: boolean },
) => (
  <NextLink {...props} className={classNames(styles.link, className)} scroll={!!scroll}>
    {children}
    {' '}
    &raquo;
  </NextLink>
);
