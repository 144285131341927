import React from 'react';
import theme from 'theme';
import Loader from 'react-spinners/PuffLoader';

import { ISpinnerProps } from './types';

export const Spinner = ({ color }: ISpinnerProps) => (
  <Loader
    color={color || theme.primary}
    size={100}
  />
);
